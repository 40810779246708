h2{
  text-align: center;
}

.h2{
  text-align: center;
}
.react-calendar-heatmap .color-scale-0 { fill: #A2D39C; }
.react-calendar-heatmap .color-scale-1 { fill: #8AB584; }
.react-calendar-heatmap .color-scale-2 { fill: #739A6C; }
.react-calendar-heatmap .color-scale-3 { fill: #5C8054; }
.react-calendar-heatmap .color-scale-4 { fill: #45643D; }
.react-calendar-heatmap .color-scale-5 { fill: #304B25; }
.react-calendar-heatmap .color-scale-6 { fill: rgb(201, 147, 124); }
.react-calendar-heatmap .color-scale-7 { fill: #AC735A; }
.react-calendar-heatmap .color-scale-8 { fill: #8E4D31; }
.react-calendar-heatmap .color-scale-9 { fill: #703706; }
.react-calendar-heatmap .color-scale-10 { fill: #531C00; }

.heatmaps{
  width:700px;
  text-align: center;
  margin: auto;
}
.react-calendar-heatmap{
  margin:0 auto;
}
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  text-align: center;
  gap:25px;
}

.centerButton {
  justify-content: center;
  align-items: center;
  text-align: center;
  
}
#quickButtons{
  display:flex;
  gap:10px;
}
#quickButtons button{
  width:45px;
  height:45px;
  border-radius: 25px;
}

#display{
  margin-top:2vmin;
}
.json-to-table{
  width:20%;
  float:left;
}

.json-to-table > table > tbody > tr > td > div{
  color:red;
  text-align: center;
  margin-bottom: 3px;
}

.leftright{
  width:45px;
  height:45px;
  font-size:large;

}
.parent {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .parent{
    display:inline;

  }
  .parent .left, .right{
    width:33%;
    display:inline;
  }
  .parent input, .parent .react-numeric-input{
    width:100%;
  }


  .right button{
    float:right;
  }
  #datepicker{
    width:100%;
    margin:0;
    gap:4px;
    text-align: center;
  }

}

#dagspris{
  width:50%;
  color:orange;
}